import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { DataListHocForDevicesTable } from '../../../DataList';

import columns from '../../columns';

import { SENSORS_LIST_ID } from '../../../UserDetails/constants';

import i18n from '../../../../i18n';
import { READ_ONLY_ROLES } from '../../../../constants';

/**
 * @module DevicesTable
 */
/**
 * Inverters tab from DevicesTable
 * @memberof module:DevicesTable
 */
const InvertersTab = (props) => {
  const {
    userId,
    myRoleType,
    devicesUrl,
    openAddDevices,
    defaultParams,
    transformResponse,
    total,
    widthSetting,
    columnsSetting,
    isReadOnlyPermission
  } = props;
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);

  return (
    <>
      {!(isReadOnlyRole || isReadOnlyPermission) && (
        <div className="devices-tab-panel col-auto">
          <a
            onClick={openAddDevices}
            className="is-large-button"
          >
            <span>{i18n.t('addInverter')}</span>
          </a>
        </div>
      )}
      <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
        <DataListHocForDevicesTable
          tab="inverters"
          listID={SENSORS_LIST_ID}
          listURL={devicesUrl}
          params={defaultParams}
          transformResponse={transformResponse}
          Component={ReactTable}
          componentProps={{
            defaultPageSize: 100,
            className: '-highlight m-datatable__table',
            columns: columns(myRoleType, userId, 'inverters', total, isReadOnlyPermission, widthSetting, columnsSetting),
            showPagination: false,
            sorted: []
          }}
        />
      </div>
    </>
  );
};

InvertersTab.propTypes = {
  myRoleType: PropTypes.string,
  userId: PropTypes.string,
  devicesUrl: PropTypes.string,
  total: PropTypes.number,
  defaultParams: PropTypes.instanceOf(Object).isRequired,
  transformResponse: PropTypes.func.isRequired,
  openAddDevices: PropTypes.func,
  widthSetting: PropTypes.instanceOf(Object),
  columnsSetting: PropTypes.instanceOf(Object),
  isReadOnlyPermission: PropTypes.bool.isRequired
};

export default InvertersTab;
