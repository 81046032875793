import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues, initialize, destroy, FormSection, change } from 'redux-form';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ModalWindow from '../../../ModalWindow';
import { PrimaryButton, CancelButton } from '../../../../components/UIKit';
import { closeModalWindow } from '../../../ModalWindow/slice';
import i18n from '../../../../i18n';
import { Expander } from '../../../../components';
import {
  editTariffId as editTariffFormID,
  tariffTypes,
  PROVIDERS_LIST_ID
} from '../../constants';

import DataList from '../../../DataList';
import { PROVIDERS_URL } from '../../../../api/apiUrls';
import { SingleTariff } from './SingleTariff';
import { DoubleTariff } from './DoubleTariff';
import { DynamicTariff } from './DynamicTariff';
import { HelionExportTariffSection } from './HelionExportTariffSection';
import { ControlSection } from './ControlSection';

const formSelector = createSelector(
  getFormValues(editTariffFormID),
  (formValues) => formValues
);

const EditTariffWindow = (props) => {
  const { reset, handleSubmit, unit, myRoleType, user, initialValues, valid } = props;
  const dispatch = useDispatch();
  const formValues = useSelector(formSelector) || {};
  const modal = useSelector((state) => state.modals[editTariffFormID]);

  const {
    importEnergy: { tariffType: tariffTypeImport },
    exportEnergy: { tariffType: tariffTypeExport },
    activeTab,
    isHelionUser
  } = formValues;

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: editTariffFormID }));
  const onTabSelect = (index) => {
    if (valid) dispatch(change(editTariffFormID, 'activeTab', index));
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  useEffect(() => {
    if (initialValues || modal?.data?.formData) {
      dispatch(initialize(editTariffFormID, { ...initialValues, ...(modal?.data?.formData || {}) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, modal?.data?.formData]);

  useEffect(() => () => dispatch(destroy(editTariffFormID)), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DataList listID={PROVIDERS_LIST_ID} listURL={PROVIDERS_URL} headers={{ 'api-version': 3 }} />
      <ModalWindow modalID={editTariffFormID}>
        <div className="modal-header">
          <h5 className="modal-title" style={{ color: '#000' }}>{i18n.t('tariffSettings')}</h5>
        </div>
        <div className="modal-body">
          <form
            id={editTariffFormID}
            onSubmit={handleSubmit}
            className="m-login__form m-form pop-up-form edit-tariff-form"
          >
            <HelionExportTariffSection formValues={formValues} myRoleType={myRoleType} unit={unit} />
            {!isHelionUser && (
              <ButtonGroup
                className="tariff-tabs"
                variant="contained"
                aria-label="Basic button group"
                sx={{
                  display: 'flex',
                  borderRadius: 2,
                  height: 30,
                  boxShadow: 'none',
                  opacity: valid ? 1 : 0.5
                }}
              >
                <Button
                  color={activeTab === 'importEnergy' ? 'primary' : 'secondary'}
                  onClick={() => onTabSelect('importEnergy')}
                  sx={{
                    flex: 1,
                    fontSize: 12,
                    textTransform: 'none',
                    cursor: valid ? 'pointer' : 'not-allowed'
                  }}
                >
                  {i18n.t('purchaseTariff')}
                </Button>
                <Button
                  color={activeTab === 'exportEnergy' ? 'primary' : 'secondary'}
                  onClick={() => onTabSelect('exportEnergy')}
                  sx={{
                    flex: 1,
                    fontSize: 12,
                    textTransform: 'none',
                    cursor: valid ? 'pointer' : 'not-allowed'
                  }}
                >
                  {i18n.t('feedInTariff')}
                </Button>
              </ButtonGroup>
            )}
            <Expander expanded={activeTab === 'importEnergy'}>
              <FormSection name="importEnergy">
                <ControlSection />
                <Expander expanded={tariffTypeImport === tariffTypes.single}>
                  <SingleTariff unit={unit} skipValidation={tariffTypeImport !== tariffTypes.single} />
                </Expander>
                <Expander expanded={tariffTypeImport === tariffTypes.double}>
                  <DoubleTariff
                    formValues={formValues.importEnergy?.doubleTariff}
                    unit={unit}
                    skipValidation={tariffTypeImport !== tariffTypes.double}
                  />
                </Expander>
                <Expander expanded={tariffTypeImport === tariffTypes.dynamic}>
                  <DynamicTariff
                    formValues={formValues}
                    initialValues={initialValues?.importEnergy?.dynamicTariff}
                    user={user}
                    skipValidation={tariffTypeImport !== tariffTypes.dynamic}
                    field="importEnergy"
                  />
                </Expander>
              </FormSection>
            </Expander>
            {!isHelionUser && (
              <Expander expanded={activeTab === 'exportEnergy'}>
                <FormSection name="exportEnergy">
                  <ControlSection />
                  <Expander expanded={tariffTypeExport === tariffTypes.single}>
                    <SingleTariff unit={unit} skipValidation={tariffTypeExport !== tariffTypes.single} />
                  </Expander>
                  <Expander expanded={tariffTypeExport === tariffTypes.double}>
                    <DoubleTariff
                      formValues={formValues.exportEnergy?.doubleTariff}
                      unit={unit}
                      skipValidation={tariffTypeExport !== tariffTypes.double}
                    />
                  </Expander>
                  <Expander expanded={tariffTypeExport === tariffTypes.dynamic}>
                    <DynamicTariff
                      formValues={formValues}
                      initialValues={initialValues?.exportEnergy?.dynamicTariff}
                      user={user}
                      skipValidation={tariffTypeExport !== tariffTypes.dynamic}
                      field="exportEnergy"
                    />
                  </Expander>
                </FormSection>
              </Expander>
            )}
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton form={editTariffFormID} isSubmit type="save" />
        </div>
      </ModalWindow>
    </>
  );
};

EditTariffWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  unit: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  valid: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: editTariffFormID,
  shouldError: () => true
})(EditTariffWindow);

export default form;
